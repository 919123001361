import GDPR from '../../static/documents/digitalvision-gdpr.pdf';
import Terms from '../../static/documents/obchodni-podminky.pdf';
import * as styles from './obchodni-podminky.module.css';

const Conditions = () => (
    <div className={styles.wrapper}>
        <p>Obchodní podmínky platné od 1.11.2019</p>
        <a
            href={GDPR}
            title="Zásady ochrany osobních a dalších zpracovávaných údajů"
            target="_blank"
            rel="noopener noreferrer"
            download="gdpr.pdf"
        >Ochrana osobních údajů
        </a>
        <a
            href={Terms}
            title="Obchodní podmínky - PDF"
            target="_blank"
            rel="noreferrer"
            download="obchodní podmínky.pdf"
        >Obchodní podmínky
        </a>
    </div>
);

export default Conditions;
